import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { useAuth } from '../../contexts/auth'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'
import CryptoJS from 'crypto-js'
import ModalDelete from '../../components/ModalDelete'

import { AiOutlineDelete, AiOutlineEdit, AiOutlineClose } from 'react-icons/ai'

import { BiLogIn } from 'react-icons/bi'
import { toast } from 'react-toastify'

import Tooltip from '@material-ui/core/Tooltip'
import { Chip } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import Select from 'react-select'
import logIcon from '../../assets/images/icons/historico.svg'
import './styles.css'
import { PageLoading } from '../../components/PageLoading/PageLoading'

interface Driver {
  id: number
  name: string
  cpf: string
  cnh: string
  isEnabled: boolean
  birthDate: Date
  shippingCompany: any
  company: any
}
interface Company {
  id: number
  name: string
}
interface Option {
  label: string
  value: string
}

const Driver: React.FC = () => {
  const { role, company } = useAuth()

  const [loading, setLoading] = useState(false)
  const [drivers, setDrivers] = useState<Driver[]>([])
  const [driversFilter, setDriversFilter] = useState<Driver[]>([])
  const [shippingOptions, setShippingOptions] = useState<Option[]>([])
  const [shippingValue, setShippingValue] = useState<Option>({} as Option)
  const [driverName, setDriverName] = useState('')

  const [modal, setModal] = useState('')
  const [id, setId] = useState(0)

  const [companies, setCompanies] = useState<Option[]>([])
  const [companyValue, setCompanyValue] = useState<Option>({} as Option)

  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanies(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    let path = ''

    if (role === 'Administrador') {
      path = `/driver/`
    } else if (role === 'Usuario') {
      path = `/driver/company/${companyId}`
    } else {
      const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
      path = `/driver/shipping-company/${shippingCompanyId}`
    }

    setLoading(true)
    api
      .get(path)
      .then(response => {
        setDrivers(response.data)
        setDriversFilter(response.data)
      })
      .finally(() => setLoading(false))

    loadInputSelect()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, role])

  function loadInputSelect() {
    handleChangeInputSelect('', 'company', setCompanies)
    handleChangeInputSelect('', 'shipping-company', setShippingOptions)
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', width: 100, hide: true },
    {
      field: 'name',
      headerName: 'Motorista',
      width: 380,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='driver-name'>
              <div>
                <div className='code'>
                  <label>Código:</label>
                  <span>{params.row.id}</span>
                </div>
                <div className='name'>
                  <span>{params.row.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'cpf', headerName: 'CPF', width: 150 },
    { field: 'cnh', headerName: 'CNH', width: 120 },
    { field: 'birthDate', headerName: 'Data de Nascimento', width: 180 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.value ? (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'green',
                  fontSize: '15px'
                }}
                label='Ativo'
              />
            ) : (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'red',
                  fontSize: '15px'
                }}
                label='Inativo'
              />
            )}
          </>
        )
      }
    },
    role !== 'Transportadora'
      ? { field: 'shippingCompany', headerName: 'Transportadora', width: 140 }
      : { field: 'shippingCompany', headerName: 'Transportadora', hide: true },
    role === 'Administrador'
      ? { field: 'company', headerName: 'Companhia', width: 140 }
      : { field: 'company', headerName: 'Companhia', hide: true },

    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 150,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/motorista/novo/${params.row.id}` }}>
                <AiOutlineEdit />
              </Link>
            </Tooltip>

            <Tooltip
              className='delete-icon'
              title={
                <h1>{role === 'Administrador' ? 'Deletar' : 'Desvincular'}</h1>
              }
            >
              <Link to={{ pathname: '' }}>
                {role === 'Administrador' ? (
                  <AiOutlineDelete
                    onClick={() => {
                      handleCorfirmDelete(params.row.id, params.row.name)
                    }}
                  />
                ) : (
                  <AiOutlineClose
                    onClick={() => {
                      handleCorfirmDelete(params.row.id, params.row.name)
                    }}
                  />
                )}
              </Link>
            </Tooltip>

            <Tooltip
              className='auth-icon'
              title={
                <div style={{ textAlign: 'center', padding: 3 }}>
                  <h1 style={{ marginBottom: '5px' }}>Logar como motorista</h1>
                  <h2 style={{ fontWeight: 'bold' }}>
                    Clique com o botão direito e abra em janela anônima{' '}
                  </h2>
                </div>
              }
            >
              <Link
                to={{
                  pathname: `${
                    document.location.origin
                  }/driver-login/${encryptCpf(
                    params.row.cpf
                  )}/${encryptBirthDate(params.row.birthDate)}`
                }}
              >
                <BiLogIn
                  onClick={() => {
                    handleDriverLogin(params.row.cpf, params.row.birthDate)
                  }}
                />
              </Link>
            </Tooltip>

            <Tooltip className='log-icon' title={<h1>Logs</h1>}>
              <Link to={{ pathname: `/motoristas/logs/${params.row.id}` }}>
                <img src={logIcon} alt='' />
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]

  const rows = drivers.map(item => {
    var birthDate
    try {
      let _birthDate = new Date(item.birthDate)
        .toISOString()
        .slice(0, 10)
        .split('-')
      birthDate = _birthDate[2] + '/' + _birthDate[1] + '/' + _birthDate[0]
    } catch {
      birthDate = '00/00/000'
    }

    return {
      id: item.id,
      name: item.name,
      cpf: item.cpf,
      status: item.isEnabled,
      cnh: item.cnh,
      birthDate: birthDate,
      shippingCompany: item.shippingCompany
        ? item.shippingCompany.name
        : 'Nenhuma',
      company: item.company ? item.company.company : 'Nenhuma'
    }
  })

  function encryptCpf(cpf: any) {
    return CryptoJS.AES.encrypt(cpf, 'SecretCpf')
      .toString()
      .replaceAll('+', 'xMl3Jk')
      .replaceAll('/', 'Por21Ld')
      .replaceAll('=', 'Ml32')
  }

  function encryptBirthDate(birthDate: any) {
    return CryptoJS.AES.encrypt(birthDate, 'SecretBirthdate')
      .toString()
      .replaceAll('+', 'xMl3Jk')
      .replaceAll('/', 'Por21Ld')
      .replaceAll('=', 'Ml32')
  }

  function handleDriverLogin(cpf: any, birthDate: any) {
    const encryptedCpf = encryptCpf(cpf)
    const encryptedBirhdate = encryptBirthDate(birthDate)

    window.open(
      `${document.location.origin}/driver-login/${encryptedCpf}/${encryptedBirhdate}`,
      '_blank',
      `location=yes,fullscreen=yes,scrollbars=yes,status=yes,menubar=yes`
    )
  }

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value.toLowerCase()

    const results = driversFilter.filter(
      driver =>
        driver.id.toString().toLowerCase().includes(search.toLowerCase()) ||
        driver.name.toString().toLowerCase().includes(search) ||
        driver.cpf.toString().includes(search)
    )
    setDrivers(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setDriverName(name)
    setId(parseInt(id))
  }

  async function handleUnbidTruck(driverId: number) {
    const url = '/driver/unbind-shipping-company'

    try {
      await api.patch(url, {
        driver: {
          id: driverId
        }
      })

      toast.success('Motorista desvinculado com sucesso')
      setTimeout(() => window.location.reload(), 1000)
    } catch (error: any) {
      toast.error('Erro ao Desvincular: ' + error.response.data.statusCode)
    }
  }

  async function handleDeleteDriver(driverId: number) {
    if (role === 'Administrador') {
      try {
        const response = await api.delete(`/driver/delete/${driverId}`)

        if (response.status === 200) {
          const results = drivers.filter(item => item.id !== driverId)

          setDrivers(results)
          toast.success('Excluido com sucesso!')
        }
      } catch (error: any) {
        if (error.response.data.message === 'ER_ROW_IS_REFERENCED_2') {
          toast.error(
            'Erro ao excluir,  existe(m) pedido(s) para este motorista!'
          )
        } else {
          toast.error(
            'Erro ao excluir: ' + error.response.data.message || error.message
          )
        }
      }
    } else {
      handleUnbidTruck(driverId)
    }

    hideModal()
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: Option[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (value === '') {
        path = `/${patch}`
      }

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }

      if (value === 'default') {
        path = `/${patch}`
      }

      if (companyValue.value !== '' && patch !== 'company') {
        path = path + `/company/${companyValue.value}`
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return {
            value: item.id,
            label: `${item.code ? item.code : item.id} - ${item.name}`
          }
        })
        setOption(options_)
      })
    }
  }

  function handleChangeOption(option: Option, setValue: any, type: string) {
    setValue(option)
    const value = Number(option.value)

    if (type === 'company') {
      const results = driversFilter.filter(item => item.company?.id === value)
      setDrivers(results)
      setShippingValue({ value: '', label: '' })
      setShippingOptions([])
    } else {
      const results = driversFilter.filter(item => {
        if (companyValue.value) {
          return (
            item.company?.id === companyValue.value &&
            item.shippingCompany?.id === value
          )
        }

        return item.shippingCompany?.id === value
      })
      setDrivers(results)
    }
  }

  return (
    <div className='driverList'>
      <ModalDelete
        type='Motorista'
        name={driverName}
        title={role === 'Transportadora' ? 'Desvincular' : 'Excluir'}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteDriver}
      />

      <header>
        <div>
          <strong>Gerenciar Motoristas</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input
              onChange={handleChangeSearch}
              placeholder='Buscar Motorista'
            />
            <MdSearch fontSize={26} color='#999999' />
          </div>

          {role === 'Administrador' && (
            <div className='company'>
              <div className='select-company' style={{ width: 320 }}>
                <label>Empresa</label>
                <Select
                  className='select-options'
                  value={companyValue}
                  options={companies}
                  onInputChange={value =>
                    handleChangeInputSelect(value, 'company', setCompanies)
                  }
                  onChange={option =>
                    handleChangeOption(
                      option as Option,
                      setCompanyValue,
                      'company'
                    )
                  }
                />
              </div>

              {companyValue && (
                <div className='truck-profile'>
                  <Button
                    className='erase-button t'
                    onClick={() => {
                      setCompanyValue({ value: '', label: '' })
                      setDrivers(driversFilter)
                    }}
                  >
                    x
                  </Button>
                </div>
              )}
            </div>
          )}

          {role !== 'Transportadora' && (
            <>
              <div className='transportadora'>
                <div className='select-shipping' style={{ width: 500 }}>
                  <label>Transportadora</label>
                  <Select
                    className='select-options'
                    value={shippingValue}
                    options={shippingOptions}
                    onInputChange={value =>
                      handleChangeInputSelect(
                        value,
                        'shipping-company',
                        setShippingOptions
                      )
                    }
                    onChange={option =>
                      handleChangeOption(
                        option as Option,
                        setShippingValue,
                        'shipping-company'
                      )
                    }
                  />
                </div>

                {shippingValue && (
                  <div className='truck-profile'>
                    <Button
                      className='erase-button t'
                      onClick={() => {
                        setShippingValue({ value: '', label: '' })
                        setDrivers(driversFilter)
                      }}
                    >
                      x
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
          <div className='new'>
            {' '}
            <Link to='/motorista/novo'>
              {' '}
              <button type='button'> Cadastrar </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      {loading ? (
        <PageLoading loading />
      ) : (
        <div className='grid'>
          <div className='content'>
            <div style={{ height: 556 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                disableColumnMenu
                components={{
                  Toolbar: GridToolbar
                }}
                pageSize={8}
                rowsPerPageOptions={[8]}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Driver
