import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react'
import { useAuth } from '../../contexts/auth'

import logoPB from '../../assets/images/logo-dark-1_a.svg'
import loadingLogo from '../../assets/images/Carregando_NEXT.svg'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import logo from '../../assets/logo.png'

import './styles.css'
import api from '../../services/api'
import CookieConsent from 'react-cookie-consent'
import { AiOutlineClose } from 'react-icons/ai'
import Draft, { convertFromHTML } from 'draft-js'
import ModalUserTerm from '../../components/ModalUserTerm'
import { useWindowIsShippingCompanyPage, useWindowLocationHost } from '../../hooks/useWindowLocationHost'
import { Helmet } from 'react-helmet'

interface Company {
  id: number
  logo: string
  name: string
}

const Login: React.FC = () => {
  const EditorState = Draft.EditorState
  const ContentState = Draft.ContentState
  const alreadyLogged = sessionStorage.getItem('@App:logged')

  const { windowLocationHost } = useWindowLocationHost()
  const { isShippingCompanyPage } = useWindowIsShippingCompanyPage()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [recaptcha, setRecaptcha] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [content, setContent] = useState<any>('')


  const [company, setCompany] = useState<Company>()
  const [modal, setModal] = useState('')
  const [close, setClose] = useState('')
  const [id, setId] = useState(
    Number(sessionStorage.getItem('@App:id')?.replace(/"/g, ''))
  )
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(''))
  )
  const [modalUseTermTitle, setModalUseTermTitle] = useState<string>('')

  useEffect(() => {
    api
      .get(`/company/host`, {
        params: {
          host: windowLocationHost
        }
      })
      .then(response => {
        setCompany(response.data)

        api.get(`/useTerm/user/company/${response.data?.id}`).then(response => {
          if (response.data?.content) {
            setModalUseTermTitle(response.data?.title)
            setContent(response.data?.content)

            const blocksFromHTML = convertFromHTML(response.data.content)
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            )

            setEditorState(EditorState.createWithContent(state))
          }
        })
      })

    loadReCaptcha('6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS')
  }, [ContentState, EditorState, windowLocationHost])

  const { Login } = useAuth()

  function handleSubmit(event: FormEvent) {
    event.preventDefault()

    setLoading(true)

    Login({
      email: email,
      company: company ? { id: company?.id } : null,
      password: password,
      recaptcha_token: recaptcha
    })
      .then(() => {
        const companyId = sessionStorage.getItem('@App:temporaryCompanyId')
        const userId = sessionStorage.getItem('@App:temporaryId')
        const role = sessionStorage.getItem('@App:userRole')

        if (
          role !== 'Administrador' &&
          role !== 'Usuario' &&
          role !== 'Transportadora'
        ) {
          toast.warning('Você não tem permissão de acesso!', {
            autoClose: 5000
          })
          setTimeout(() => {
            setLoading(false)
          }, 2500)
        }

        if (role === 'Administrador' || role === 'Usuario') {
          window.location.assign('/dashboard')
        } else if (role === 'Transportadora' && userId !== '') {
          window.location.assign('/expedicao/transportadora')
        }

        if (companyId && userId && content) {
          window.location.assign(
            `/aprovar-termo/transportadora/empresa/${companyId}/${userId}`
          )
        }
      })
      .catch(e => {
        if (
          e?.response?.data?.message ===
          'Usuário bloqueado, fale com o administrador do sistema!'
        ) {
          toast.error(
            `Usuário bloqueado, fale com o administrador do sistema!`,
            { autoClose: 10000 }
          )
        } else {
          toast.error('Usuário ou senha inválido')
        }
        setLoading(false)
      })
  }

  function handleLoginChange(event: ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value)
  }

  function handlePassChange(event: ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmModal() {
    showModal()
    setId(id)
  }

  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  const verifyCallback = (recaptchaToken: any) => {
    setRecaptcha(recaptchaToken)
    // Here you will get the final recaptchaToken!!!
  }

  function handleAccept() {
    document.cookie = 'userTerm=accepted'
  }

  return (
    <div className='login'>
      <Helmet>
        <title>{isShippingCompanyPage ? 'Login | Transportadoras' : 'Login'}</title>
      </Helmet>


      <div className='container'>
        <ModalUserTerm
          type='Termo'
          id={id}
          title={modalUseTermTitle}
          className={modal}
          handleClose={handleCloseModal}
          editorState={editorState}
        />

        <div className='login-box'>
          <h1>Login</h1>
          <form onSubmit={handleSubmit}>
            <input
              type='email'
              name='email'
              placeholder='e-mail'
              value={email}
              onChange={handleLoginChange}
            />
            <input
              type='password'
              name='p'
              placeholder='Senha'
              value={password}
              onChange={handlePassChange}
            />

            <button
              id='button-login'
              type='submit'
              className='btn btn-primary btn-block btn-large'
              style={{ backgroundColor: loading ? '#323B8D' : '' }}
            >
              {loading ? (
                <img alt='' width={100} height={20} src={loadingLogo} />
              ) : (
                'Entrar'
              )}
            </button>
          </form>
          <div className='privacy-policy '>
            <button
              onClick={() => {
                handleCorfirmModal()
              }}
              className='settings'
            >
              {alreadyLogged !== 'true'
                ? 'Leia nossa politica de Privacidade e Termos de Uso'
                : 'Politica de Privacidade e Termos de Uso'}
            </button>
          </div>
        </div>
        <div className='logo'>
          <img
            src={
              company?.logo
                ? `${process.env.REACT_APP_API}/${company?.logo}`
                : logo
            }
            alt=''
          />
        </div>

        {!document.cookie.match('userTerm') && (
          <div className={close + ''}>
            <CookieConsent
              location='bottom'
              buttonText=''
              cookieName='acceptedUseTerm'
              buttonStyle={{
                color: '#dedede',
                backgroundColor: '#fff',
                fontSize: '13px'
              }}
              expires={150}
            >
              <div className='body'>
                <div>
                  Usamos cookies para personalizar conteúdos e melhorar a sua
                  experiência
                </div>
                <button
                  onClick={() => {
                    handleCorfirmModal()
                  }}
                  className='settings'
                >
                  Leia nossa politica de Privacidade e Termos de Uso{' '}
                </button>
                <button
                  onClick={() => {
                    handleAccept()
                    setClose('none')
                  }}
                  className='send'
                >
                  Entendi e aceito
                </button>
                <button
                  onClick={() => {
                    setClose('none')
                  }}
                  className='close'
                >
                  <AiOutlineClose />
                </button>
              </div>
            </CookieConsent>
          </div>
        )}

        <ReCaptcha
          //ref={ref => this.recaptcha = ref}
          sitekey={'6LdcCZ4dAAAAAJM6_yCOqvahBvE5w8c14HMQbMJS'}
          action='action_name'
          verifyCallback={verifyCallback}
        />
      </div>

      <div className='powerBy'>
        <div>Powered by</div>
        <div>
          <img style={{ width: 170, marginTop: 10 }} src={logoPB} alt='' />
        </div>
      </div>
    </div>
  )
}
export default Login
