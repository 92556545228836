import api from '../../services/api'
import {
  CompanyApi,
  CompanyAttendanceStatusApi,
  CompanyHostApi,
  SettingsCompanyApi
} from './companyTypes'

async function getList() {
  const response = await api.get<CompanyApi[]>('/company')
  return response.data
}

async function getSettings(companyId: string) {
  const response = await api.get<SettingsCompanyApi>(
    `/settings/company/${companyId}`
  )
  return response.data
}

async function getCompanyByHost(host: string) {
  const response = await api.get<CompanyHostApi>('/company/host', {
    params: {
      host
    }
  })

  return response.data
}

async function getAttendanceStatus(companyId: string) {
  const response = await api.get<CompanyAttendanceStatusApi>(
    `/attendances/today/company/${companyId}`
  )
  return response.data
}

export const companyApi = {
  getList,
  getSettings,
  getCompanyByHost,
  getAttendanceStatus
}
