import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import Input from '../../../components/Input'
import api from '../../../services/api'

import * as Yup from 'yup'
import { useFormik } from 'formik'

import './styles.css'
import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import CSVReader, { IFileInfo } from 'react-csv-reader'
import { useAuth } from '../../../contexts/auth'
import Axios from 'axios'
import { Checkbox } from '@material-ui/core'

interface RouteParams {
  id: string
}

interface Contact {
  id: number
  name: string
  fone: string
  email: string
  whatsapp: boolean
}

interface Company {
  id: number
}
interface Authorization {
  hasEmail: boolean
  hasSMS: boolean
  hasWhats: boolean
  id: number
}
interface User {
  id: number
  email: string
  authorization: Authorization
}
interface ShippingCompany {
  id: number
  code: string
  cnpj: string
  name: string
  street: string
  number: string
  complement: string
  city: string
  stateRegistration: string
  uf: string
  zipCode: string
  company: Company
  contact?: Contact[]
  user?: User[]
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: '20px',
      color: '#444',
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(24),
      color: theme.palette.text.secondary
    }
  })
)

// Validate data from form
const companySchema = Yup.object().shape({
  name: Yup.string()
    .max(80, 'Tamanho máximo de 80 caracteres')
    .required('Nome fantasia é obrigatório!'),
  cnpj: Yup.string().required('Número de CNPJ é obrigatório!'),
  stateRegistration: Yup.string().required('Inscrição Estadual é obrigatório!'),
  uf: Yup.string().required('Estado é obrigatório!'),
  street: Yup.string()
    .max(25, 'Tamanho máximo de 25 caracteres')
    .required('Nome da rua é obrigatório!'),
  number: Yup.number().nullable(),
  complement: Yup.string()
    .nullable()
    .max(25, 'Tamanho máximo de 25 caracteres'),
  city: Yup.string()
    .max(50, 'Tamanho máximo de 50 caracteres')
    .required('Nome da cidade é obrigatório!'),
  district: Yup.string().required('Nome do bairro é obrigatório!'),
  zipCode: Yup.string().required('CEP é obrigatório!')
})

const NewEditShippingCompany: React.FC = (): JSX.Element => {
  const { id } = useParams<RouteParams>()

  const { company } = useAuth()
  const companyId = localStorage.getItem('@App:companyId')

  const [WhatsAppAtive, setWhatsAppAtive] = useState<boolean>(false)

  const [zipCode, setZipeCode] = useState<string>('')

  const [password, setPassword] = useState<string>('')
  const [login, setLogin] = useState<string>('')

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const [shippingCompanys, setShippingCompanys] = useState<ShippingCompany[]>(
    []
  )
  const [contacts, setContacts] = useState<Contact[]>([])
  const [contact, setContact] = useState<string>('')
  const [contactFone, setContactFone] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [idContact, setIdContact] = useState(0)
  const [key, setKey] = useState('')
  const [status, setStatus] = useState<Boolean>(true)

  // Add state at component level
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    handleSubmit,
    values,
    setValues,
    handleChange,
    errors,
  } = useFormik({
    initialValues: {
      id: 0,
      code: '',
      name: '',
      cnpj: '',
      uf: '',
      street: '',
      number: '',
      complement: '',
      city: '',
      district: '',
      zipCode: '',
      stateRegistration: '',
      isEnabled: false,
      user: [
        {
          id: 0,
          email: '',
          authorization: [
            {
              hasEmail: false,
              hasSMS: false,
              hasWhats: false,
              id: 0
            }
          ]
        }
      ]
    },
    validationSchema: id ? companySchema : null,
    onSubmit: async values => {


      setIsSubmitting(true);

      try {
        if (id) {
          const payload = {
            id: parseInt(id),
            code: values.code,
            name: values.name,
            cnpj: values.cnpj.slice(0, 18),
            uf: values.uf,
            street: values.street,
            number: values.number,
            complement: values.complement,
            city: values.city,
            district: values.district,
            zipCode: values.zipCode,
            stateRegistration: values.stateRegistration,
            company: { id: company || parseInt(companyId || '') || null },
            contact: contacts,
            login: { login, password },
            isEnabled: status
          }

          await api.post('/shipping-company/create', payload);
          toast.success('Cadastro realizado com sucesso');
          setTimeout(() => {
            history.push('/transportadoras');
          }, 2500);
        } else {
          await api.post('/shipping-company/import', shippingCompanys);
          toast.success('Cadastro realizado com sucesso');
        }
      } catch (e: any) {
        toast.error('Erro ao Cadastrar: ' + e.response.data.code);
      } finally {
        setIsSubmitting(false);
      }
    }
  })

  const handleChangeAccordion =
    (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  function handleSetPassword(password: string) {
    setPassword(password)
  }

  function handleSetLogin(login: string) {
    setLogin(login)
  }

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: 0
  })

  const history = useHistory()

  useEffect(() => {
    setExpanded('contact')

    if (id) {
      api.get(`/shipping-company/${id}`).then(response => {
        setValues({
          id: response.data?.id,
          code: response.data?.code,
          cnpj: response.data?.cnpj,
          name: response.data?.name,
          uf: response.data?.uf,
          street: response.data?.street || '',
          number: response.data?.number || '',
          complement: response.data?.complement || '',
          city: response.data?.city || '',
          district: response.data?.district || '',
          stateRegistration: response.data?.stateRegistration,
          zipCode: response.data?.zipCode,
          isEnabled: response.data?.isEnabled,
          user: response.data?.user
        })

        setStatus(response.data?.isEnabled)
        setZipeCode(response.data?.zipCode)
        setContacts(response.data.contact)

        const user = response.data?.user[0].email

        setLogin(user || '')
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'id', width: 95, hide: true },
    { field: 'code', headerName: 'Code', width: 95 },
    { field: 'name', headerName: 'Name', width: 400 },
    { field: 'cnpj', headerName: 'CNPJ', width: 160 },
    { field: 'uf', headerName: 'UF', width: 80 },
    { field: 'city', headerName: 'Cidade', width: 140 },
    { field: 'zipCode', headerName: 'CEP', width: 140 }
  ]

  // add linhas a tabela
  const rows = shippingCompanys.map(item => {
    return {
      id: item.id,
      code: item.code,
      name: item.name,
      cnpj: item.cnpj,
      uf: item.uf,
      city: item.city,
      zipCode: item.zipCode
    }
  })

  function handleAddContact() {
    setContacts([
      ...contacts,
      {
        id: 0,
        name: contact,
        fone: contactFone,
        email: contactEmail,
        whatsapp: WhatsAppAtive
      }
    ])
    setContact('')
    setContactFone('')
    setContactEmail('')
    setWhatsAppAtive(false)
  }

  async function handleChangeContact(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContact(event.target.value)
  }
  async function handleChangeContactFone(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactFone(event.target.value.slice(0, 16))
  }

  async function handleChangeContactEmail(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setContactEmail(event.target.value)
  }

  async function handleEditContact(
    id: number,
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    const value =
      type === 'fone' ? event.target.value.slice(0, 16) : event.target.value
    const newContacts = contacts.map(item => {
      if (item.id === id)
        return {
          id: id,
          name: type === 'name' ? value : item.name,
          fone: type === 'fone' ? value : item.fone,
          email: type === 'email' ? value : item.email,
          whatsapp:
            type === 'whatsapp' ? Boolean(value) : Boolean(item.whatsapp)
        }
      else return item
    })

    setContacts(newContacts)
  }

  function onEdit(id: number) {
    setInEditMode({
      status: true,
      rowKey: id
    })
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(contact: Contact) {
    showModal()
    setNameModal(contact.name)
    setIdContact(contact.id)
    setKey(contact.fone)
  }

  function handleCsvFile(data: Array<any>, info: IFileInfo) {
    let lines: ShippingCompany[] = []
    data.forEach((item, index) => {
      if (index === 0 || item[0] === '') return

      lines.push({
        id: index,
        code: item[0],
        name: item[1],
        cnpj: item[2],
        street: item[3],
        number: item[4],
        complement: item[5],
        uf: item[6],
        city: item[7],
        zipCode: item[8],
        stateRegistration: item[9],
        company: { id: company || parseInt(companyId || '') || 0 },
        contact:
          item[11] || item[12] || item[13]
            ? [
              {
                id: 0,
                name: item[10],
                fone: `(${item[11]}) ${item[12]}`,
                email: item[13],
                whatsapp: item['']
              }
            ]
            : []
      })
    })
    setShippingCompanys(lines)
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/contact/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = contacts.filter(item => item.id !== id)
            setContacts(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = contacts.filter(item => item.fone !== key)
      setContacts(results)
    }
  }

  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  async function handleChangeZipCode(zipCode: string) {
    const _zipCode = zipCode
    if (zipCode.length > 8) {
      _zipCode.replace('/[^0-9]/', '')
      await Axios.get(`https://viacep.com.br/ws/${zipCode}/json/`).then(
        response => {
          setValues({
            ...values,
            city: response.data.localidade,
            district: response.data.bairro,
            street: response.data.logradouro,
            uf: response.data.uf,
            complement: response.data.complemento,
            zipCode: zipCode
          })
        }
      )
    }
    setZipeCode(zipCode)
  }

  function handleWhatapp() {
    setWhatsAppAtive(!WhatsAppAtive)
  }


  return (
    <div className='new-company-container'>
      <ModalDelete
        type='Contato'
        name={nameModal}
        id={idContact}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />
      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!id ? ' Importar' : id === '0' ? 'Cadastrar' : 'Editar'}`}{' '}
              transportadora{!id ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header'>
            <Link to='/transportadoras'>
              <button id='btBack' type='button'>
                <MdKeyboardArrowLeft size={20} color='#fff' />
                Voltar
              </button>
            </Link>
            <button id='btSave' type='submit' disabled={isSubmitting}>
              <MdDone size={20} color='#fff' />
              {isSubmitting ? 'Salvando...' : 'Salvar'}
            </button>
          </div>
        </header>

        {!id ? (
          <div>
            <br />
            <span>
              {' '}
              <Link
                target='blank'
                to='../modelscsv/CadastroTransportadoras.csv'
              >
                Modelo CSV para importação
              </Link>
            </span>
            <br />
            <CSVReader
              fileEncoding='ISO-8859-1'
              onFileLoaded={(data, fileInfo) => handleCsvFile(data, fileInfo)}
            />
            <br />
            {rows.length !== 0 && (
              <div style={{ height: 370 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  pageSize={5}
                  disableColumnMenu
                  rowsPerPageOptions={[8]}
                  localeText={
                    ptBR.components.MuiDataGrid.defaultProps.localeText
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <div className='content'>
            <div className='row'>
              <div className={`input ${errors.cnpj ? 'error' : ''}`}>
                <Input
                  name='cnpj'
                  mask='##.###.###/####'
                  placeholder='CNPJ'
                  onChange={handleChange}
                  value={values.cnpj.slice(0, 18)}
                  label='CNPJ'
                  maxLength={14}
                />

                {errors.cnpj && (
                  <div className='validateError'>{errors.cnpj}</div>
                )}
              </div>

              <div
                className={`input ${errors.stateRegistration ? 'error' : ''}`}
              >
                <Input
                  name='stateRegistration'
                  placeholder='Inscrição estadual'
                  onChange={handleChange}
                  value={values.stateRegistration}
                  label='Inscrição Estadual'
                  maxLength={25}
                />
                {errors.stateRegistration && (
                  <div className='validateError'>
                    {errors.stateRegistration}
                  </div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.name ? 'error' : ''}`}>
                <Input
                  name='name'
                  placeholder='Nome fantasia da empresa'
                  onChange={handleChange}
                  value={values.name}
                  label='Nome fantasia'
                  maxLength={25}
                />
                {errors.name && (
                  <div className='validateError'>{errors.name}</div>
                )}
              </div>

              <div className={`input ${errors.zipCode ? 'error' : ''}`}>
                <Input
                  mask='#####-###'
                  name='zipCode'
                  placeholder='CEP'
                  value={zipCode}
                  onChange={e => handleChangeZipCode(e.target.value)}
                  label='CEP'
                  maxLength={9}
                />

                {errors.zipCode && (
                  <div className='validateError'>{errors.zipCode}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.street ? 'error' : ''}`}>
                <Input
                  name='street'
                  placeholder='Rua'
                  value={values.street}
                  onChange={handleChange}
                  label='Rua'
                  maxLength={25}
                />

                {errors.street && (
                  <div className='validateError'>{errors.street}</div>
                )}
              </div>

              <div className={`input ${errors.city ? 'error' : ''}`}>
                <Input
                  name='city'
                  placeholder='Cidade'
                  value={values.city}
                  onChange={handleChange}
                  label='Cidade'
                  maxLength={25}
                />

                {errors.city && (
                  <div className='validateError'>{errors.city}</div>
                )}
              </div>
            </div>

            <div className='row'>
              <div className={`input ${errors.district ? 'error' : ''}`}>
                <Input
                  name='district'
                  placeholder='Bairro'
                  value={values.district}
                  onChange={handleChange}
                  label='Bairro'
                  maxLength={25}
                />

                {errors.district && (
                  <div className='validateError'>{errors.district}</div>
                )}
              </div>

              <div className={`input ${errors.number ? 'error' : ''}`}>
                <Input
                  name='number'
                  placeholder='Numero'
                  value={values.number}
                  onChange={handleChange}
                  label='Numero'
                  maxLength={5}
                />
              </div>
            </div>

            <div className='row'></div>

            <div className='row'>
              <div className='input'>
                <Input
                  name='complement'
                  placeholder='Complemento'
                  value={values.complement}
                  onChange={handleChange}
                  label='Complemento'
                />
              </div>

              <div className={`uf input ${errors.uf ? 'error' : ''}`}>
                <Input
                  name='uf'
                  placeholder='Estado'
                  value={values.uf}
                  onChange={handleChange}
                  label='Estado'
                  maxLength={2}
                />

                {errors.uf && <div className='validateError'>{errors.uf}</div>}
              </div>
            </div>
            <div className='termosContato row'>
              <div className='termosContent'>
                <h1>Contato:</h1>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasWhats'
                    checked={values.user[0]?.authorization[0]?.hasSMS}
                    value={values.user[0]?.authorization[0]?.hasSMS}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens sms</label>
                </div>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasWhats'
                    checked={values.user[0]?.authorization[0]?.hasWhats}
                    value={values.user[0]?.authorization[0]?.hasWhats}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar mensagens ao whatsapp</label>
                </div>
                <div className='termoCheckbox'>
                  <Checkbox
                    name='hasWhats'
                    checked={values.user[0]?.authorization[0]?.hasEmail}
                    value={values.user[0]?.authorization[0]?.hasEmail}
                    onChange={handleChange}
                    color='default'
                    size='medium'
                  />
                  <label>Autorizar envio de mensagens ao email</label>
                </div>
              </div>
            </div>

            <div className='accordion-session'>
              <Accordion
                expanded={expanded === 'contact'}
                onChange={handleChangeAccordion('contact')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className={classes.heading}>Contatos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className='contacts'>
                    <table>
                      <thead>
                        <tr>
                          <th>Contato</th>
                          <th>Telefone</th>
                          <th>Email</th>
                          <th>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {contacts.length > 0 ? (
                          contacts.map((contact, index) => (
                            <tr key={index}>
                              <td>
                                {inEditMode.status &&
                                  inEditMode.rowKey === index ? (
                                  <Input
                                    value={contact.name}
                                    onChange={event =>
                                      handleEditContact(
                                        contact.id,
                                        event,
                                        'name'
                                      )
                                    }
                                  />
                                ) : (
                                  contact.name
                                )}
                              </td>
                              <td
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <AiOutlineWhatsApp
                                  size={18}
                                  color={!contact.whatsapp ? 'gray' : 'green'}
                                />
                                {inEditMode.status &&
                                  inEditMode.rowKey === index ? (
                                  <Input
                                    mask='(##) # ####-####'
                                    value={contact.fone}
                                    onChange={event =>
                                      handleEditContact(
                                        contact.id,
                                        event,
                                        'fone'
                                      )
                                    }
                                  />
                                ) : (
                                  contact.fone
                                )}
                              </td>
                              <td>
                                {inEditMode.status &&
                                  inEditMode.rowKey === index ? (
                                  <Input
                                    value={contact.email}
                                    onChange={event =>
                                      handleEditContact(
                                        contact.id,
                                        event,
                                        'email'
                                      )
                                    }
                                  />
                                ) : (
                                  contact.email
                                )}
                              </td>

                              <td>
                                <button
                                  type='button'
                                  className='actionButton blue'
                                  onClick={() => onEdit(index)}
                                >
                                  editar
                                </button>

                                <button
                                  type='button'
                                  className='actionButton red'
                                  onClick={() => {
                                    handleCorfirmDelete(contact)
                                  }}
                                >
                                  excluir
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} style={{ textAlign: 'center' }}>
                              Nenhum resultado foi encontrado
                            </td>
                          </tr>
                        )}
                        <tr className='contact'>
                          <td>
                            <Input
                              name='contato'
                              placeholder='Contato'
                              value={contact}
                              onChange={handleChangeContact}
                              label=''
                            />
                          </td>
                          <td style={{ display: 'flex', alignItems: 'center' }}>
                            <AiOutlineWhatsApp
                              size={32}
                              color={!WhatsAppAtive ? 'gray' : 'green'}
                              onClick={handleWhatapp}
                            />
                            <Input
                              mask='(##) # ####-####'
                              name='fone'
                              placeholder='Telefone'
                              value={contactFone}
                              onChange={handleChangeContactFone}
                              label=''
                            />
                          </td>
                          <td>
                            <Input
                              name='email'
                              placeholder='E-mail'
                              value={contactEmail}
                              onChange={handleChangeContactEmail}
                              label=''
                            />
                          </td>
                          <td>
                            <button
                              type='button'
                              id='addContact'
                              onClick={handleAddContact}
                              {...(!contact || !contactFone || !contactEmail
                                ? { disabled: true }
                                : { disabled: false })}
                            >
                              + Adicionar
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 'login'}
                onChange={handleChangeAccordion('login')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1bh-content'
                  id='panel1bh-header'
                >
                  <Typography className={classes.heading}>
                    Dados de Login
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    Atualize seus dados de acesso ou crie uma nova senha
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Input
                    name='login'
                    placeholder='Login'
                    type='email'
                    value={login}
                    onChange={e => handleSetLogin(e.target.value)}
                    label='Login: '
                  />
                  <Input
                    name='password'
                    placeholder='Senha:'
                    type='password'
                    value={password}
                    onChange={e => handleSetPassword(e.target.value)}
                    label='Senha'
                    maxLength={11}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

export default NewEditShippingCompany
