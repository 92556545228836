import { companyApi } from './companyApi'

async function getList() {
  const companys = companyApi.getList()
  return companys
}

async function getSettings(companyId: string) {
  const settingsCompany = await companyApi.getSettings(companyId)
  return settingsCompany
}

async function getCompanyByHost(host: string) {
  const company = await companyApi.getCompanyByHost(host)
  return company
}

/**
@param companyId
@returns the attendance status of the company is open or not.
**/

async function getAttendanceStatus(companyId: string) {
  const data = await companyApi.getAttendanceStatus(companyId)
  return data
}

export const companyServices = {
  getList,
  getSettings,
  getCompanyByHost,
  getAttendanceStatus
}
