export * from './localStorage'

/**
 * Checks if the HTTP status code indicates a successful request
 * @param status - The HTTP status code to check
 * @returns True if status is in the 2xx range (200-299)
 */
export function isSuccessRequest(status: number) {
  return status >= 200 && status < 300
}

/**
 * Checks if the HTTP status code indicates a client error
 * @param status - The HTTP status code to check
 * @returns True if status is in the 4xx range (400-499)
 */
export function isErrorRequest(status: number) {
  return status >= 400 && status < 500
}

/**
 * Checks if the HTTP status code indicates a server error
 * @param status - The HTTP status code to check
 * @returns True if status is in the 5xx range (500-599)
 */
export function isServerError(status: number) {
  return status >= 500 && status < 600
}
