import { shippingCompanyApi } from './shippingCompanyApi'

async function getList() {
  const response = await shippingCompanyApi.getList()
  return response
}

export const shippingCompanyService = {
  getList
}
