import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineWarning
} from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../../services/api'

import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import { Chip } from '@material-ui/core'
import './styles.css'
import { PageLoading } from '../../components/PageLoading/PageLoading'
import { useShippingCompanyGetList } from '../../domain/ShippingCompany'
import { isSuccessRequest } from '../../utils'


const ShippingCompany: React.FC = () => {
  const { isAdminRoot } = useAuth()

  const { shippingCompanies, loading: loadingShippingCompanies, refetch } =
    useShippingCompanyGetList()

  const [search, setSearch] = useState('')

  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)
  const [adminRoot, setAdminRoot] = useState<boolean>()

  useEffect(() => {
    isAdminRoot().then(result => setAdminRoot(result))
  }, [isAdminRoot])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id', width: 95, hide: true },
    {
      field: 'name',
      headerName: 'Name',
      width: 580,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='shippingCompany'>
              <div>
                <div className='code'>
                  <label>Código:</label>
                  <span>{params.row.code}</span>
                </div>
                <div className='name'>
                  <span>{params.row.name}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'cnpj', headerName: 'CNPJ', width: 160 },
    { field: 'uf', headerName: 'UF', width: 80 },
    { field: 'city', headerName: 'Cidade', width: 140 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.status ? (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'green',
                  fontSize: '15px'
                }}
                label='Ativo'
              />
            ) : (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'red',
                  fontSize: '15px'
                }}
                label='Inativo'
              />
            )}
          </>
        )
      }
    },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 110,
      hide: !adminRoot,
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/transportadora/novo/${params.row.id}` }}>
                {' '}
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>

            <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
              <Link to={{ pathname: `` }}>
                <AiOutlineDelete
                  onClick={() => {
                    handleCorfirmDelete(params.row.id, params.row.name)
                  }}
                />
              </Link>
            </Tooltip>
            {!params.row.hasUser && (
              <Tooltip
                className='warning-icon'
                title={<h2>Esta Transportadora não possui senha cadastrada</h2>}
              >
                <Link to={{ pathname: `` }}>
                  <AiOutlineWarning />
                </Link>
              </Tooltip>
            )}
          </>
        )
      }
    }
  ]

  const rowsFiltered =
    search && search.length > 0 ? handleChangeSearch(search) : shippingCompanies

  const rows = rowsFiltered.map(item => {
    return {
      id: item.id,
      code: item.code,
      name: item.name,
      cnpj: item.cnpj,
      uf: item.uf,
      status: item.isEnabled,
      city: item.city,
      hasUser: item.user && item.user.length > 0
    }
  })

  function handleChangeSearch(search: string) {
    const results = shippingCompanies.filter(
      company =>
        company?.name?.toLowerCase().includes(search) ||
        company?.cnpj?.toLowerCase().includes(search) ||
        company?.code?.toString().includes(search)
    )
    return results
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleDeleteShippingCompany(id: number) {
    hideModal()

    api
      .delete(`/shipping-company/delete/${id}`)
      .then(response => {
        if (isSuccessRequest(response.status)) {
          toast.success('Excluido com sucesso!')
          refetch()
        }
      })
      .then(() => {
        toast.success('Excluido com sucesso!')
      })
      .catch(e => {
        if (e.response.data.message === 'ER_ROW_IS_REFERENCED_2')
          toast.error(
            'Erro ao excluir,  existe(m) pedido(s) para esta transportadora!'
          )
        else
          toast.error(
            'Erro ao excluir: ' + e.response.data.message || e.message
          )
      })
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  return (
    <div className='shippingCompanyList'>
      <ModalDelete
        type='Transportadora'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteShippingCompany}
      />

      <header>
        <div>
          <strong>Gerenciar transportadoras</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input
              onChange={e => setSearch(e.target.value)}
              value={search}
              placeholder='Buscar empresa por código ou nome'
            />
            <MdSearch fontSize={26} color='#999999' />
          </div>

          <div className='new'>
            <Link to='/transportadora/novo'>
              {' '}
              <button type='button'> Importar </button>{' '}
            </Link>

            <Link to='/transportadora/novo/0'>
              {' '}
              <button type='button'> Nova transportadora </button>{' '}
            </Link>
          </div>
        </div>
      </header>

      {loadingShippingCompanies ? (
        <PageLoading loading />
      ) : (
        <div className='grid'>
          <div className='content'>
            <div style={{ height: 556 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={8}
                disableColumnMenu
                components={{
                  Toolbar: GridToolbar
                }}
                rowsPerPageOptions={[8]}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ShippingCompany
