import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import styles from './styles.module.css'

import { Link, useHistory, useParams } from 'react-router-dom'
import { MdDone, MdKeyboardArrowLeft } from 'react-icons/md'
import Input from '../../../components/Input'
import api from '../../../services/api'

import { useFormik } from 'formik'

import { toast } from 'react-toastify'
import ModalDelete from '../../../components/ModalDelete'
import { useAuth } from '../../../contexts/auth'
import { useCompanyGetList } from '../../../domain'
import { isSuccessRequest } from '../../../utils'

interface RouteParams {
  id: string
}



interface IOption {
  label: string
  value: string
}



interface Product {
  id: number
  name: string
}

interface OrderItem {
  id: number
  quantity: number
  product: Product
}

const SelectCustomStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '100%'
  }),
  menu: (provided: any) => ({
    ...provided,
    width: '100%'
  })
}

const NewEditOrder: React.FC = (): JSX.Element => {
  const history = useHistory()
  const { id } = useParams<RouteParams>()

  const isEditMode = !!id

  const { companys } = useCompanyGetList()

  const [companySelected, setCompanySelected] = useState<IOption>()

  const [customerOptions, setCustomerOptions] = useState<IOption[]>([
    { value: '', label: '' }
  ])
  const [customerValue, setCustomerValue] = useState<IOption>({
    value: '',
    label: ''
  })

  const [shippingOptions, setShippingOptions] = useState<IOption[]>([])
  const [shippingValue, setShippingValue] = useState<IOption[]>([])

  const [productOptions, setProductOptions] = useState<IOption[]>([
    { value: '', label: '' }
  ])
  const [productValue, setProductValue] = useState<Product>({ id: 0, name: '' })

  const [orderItems, setOrderItems] = useState<OrderItem[]>([])
  const [quantity, setQuantity] = useState<string>('')
  const [quantityTransported, setQuantityTransported] = useState<string>('')

  const [modal, setModal] = useState('')
  const [nameModal, setNameModal] = useState('')
  const [orderItemId, setOrderItemId] = useState(0)
  const [key, setKey] = useState(0)

  const [sendEmail, setSendEmail] = useState(false)

  const { role, company } = useAuth()

  const { handleSubmit, values, setValues, handleChange, errors } = useFormik({
    initialValues: {
      id: '',
      code: ''
    },

    onSubmit: async values => {
      const shippingCompanyIds = shippingValue.map(option => ({
        id: parseInt(option.value)
      }))

      const payload = {
        id: parseInt(values.id),
        code: values.code,
        customer: { id: customerValue.value },
        qtyTransported: quantityTransported,
        sendEmail: sendEmail,
        shippingCompany: shippingCompanyIds,
        orderItem: orderItems,
        company: { id: Number(companySelected?.value) }
      }

      try {
        const response = await api.post('/order/save', payload)
        if (isSuccessRequest(response.status)) {
          setTimeout(() => history.push('/pedidos'), 2500)
          toast.success('Cadastro realizado com sucesso')
        }
      } catch (error: any) {
        toast.error('Erro ao Cadastrar: ' + error.response.data.message)
      }
    }
  })

  useEffect(() => {
    if (id) {
      api.get(`/order/${id}`).then(response => {
        setValues({
          id: response.data.id,
          code: response.data.code
        })

        const company = {
          label: response.data.company.name,
          value: String(response.data.company.id)
        }
        setCompanySelected(company)

        setQuantityTransported(response.data?.qtyTransported)
        const { customer } = response.data
        setCustomerValue({
          value: customer.id,
          label: `${customer.code} -  ${customer.name}`
        })

        const { shippingCompany } = response.data

        const shippingCompanyOptions = shippingCompany.map((option: any) => ({
          value: option?.id,
          label: `${option?.code} - ${option?.name}`
        }))

        setShippingValue(shippingCompanyOptions)

        setOrderItems(response.data.orderItem)
        if (response.data.orderItem[0]) {
          setProductValue({
            id: parseInt(response.data.orderItem[0].product.id),
            name: response.data.orderItem[0].product.name
          })
          setProductOptions([
            {
              value: response.data.orderItem[0].product.id,
              label: response.data.orderItem[0].product.name
            }
          ])
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: IOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }
      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return { value: item.id, label: `${item.code} - ${item.name}` }
        })
        setOption(options_)
      })
    }
  }

  function handleChangeOptionCustomer(option: IOption) {
    setCustomerValue(option)
  }

  function handleChangeOptionProduct(option: IOption) {
    setProductValue({
      id: parseInt(option.value),
      name: option.label.substring(option.label.indexOf('-') + 2)
    })
  }

  function handleChangeOptionShipping(options: IOption[]) {
    setShippingValue(options)
  }

  function handleChangeQuantity(event: React.ChangeEvent<HTMLInputElement>) {
    setQuantity(event.target.value)
  }

  function handleChangeQuantityTransported(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setQuantityTransported(event.target.value)
  }

  function handleAddProduct() {
    setOrderItems([
      ...orderItems,
      {
        id: 0,
        quantity: parseInt(quantity),
        product: { id: productValue.id, name: productValue.name }
      }
    ])
    setQuantity('')
  }

  function handleCorfirmDelete(orderItem: OrderItem) {
    showModal()
    setNameModal(orderItem.product.name)
    setOrderItemId(orderItem.id)
    setKey(orderItem.id)
  }

  function handleDeleteContact(id: number) {
    hideModal()

    if (id) {
      api
        .delete(`/orderItem/delete/${id}`)
        .then(response => {
          if (response.status === 200) {
            toast.success('Excluido com sucesso!')
            const results = orderItems.filter(item => item.id !== id)
            setOrderItems(results)
          }
        })
        .catch(() => {
          toast.success('Erro ao excluir!')
        })
    } else {
      const results = orderItems.filter(item => item.id !== key)
      setOrderItems(results)
    }
  }

  function handlesendEmail() {
    setSendEmail(!sendEmail)
  }

  function handleCloseModal() {
    hideModal()
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  const companysOptions: IOption[] = companys.map(company => ({
    label: company.name,
    value: company.id.toString()
  }))

  function handleChangeCompanyOption(option: IOption) {
    setCompanySelected(option)
  }


  function formatOrderCode(value: string): string {
    // Remove any non-alphanumeric characters
    let cleanValue = value.replace(/[^0-9a-zA-Z]/g, '');

    // Limit raw input to 9 characters (3 + 4 + 2)
    if (cleanValue.length > 9) {
      cleanValue = cleanValue.slice(0, 9);
    }

    let formattedValue = cleanValue;

    // Format first 7 digits with dot
    if (cleanValue.length > 3) {
      formattedValue = cleanValue.slice(0, 3) + '.' + cleanValue.slice(3);
    }

    // Add hyphen before last 2 characters if they exist
    if (cleanValue.length > 7) {
      const letters = cleanValue.slice(7).toUpperCase();
      formattedValue = cleanValue.slice(0, 3) + '.' + cleanValue.slice(3, 7) + '-' + letters;
    }

    return formattedValue;
  };




  return (
    <div className='new-order-container'>
      <ModalDelete
        type='Produto'
        name={nameModal}
        id={orderItemId}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteContact}
      />

      <form onSubmit={handleSubmit} autoComplete='off'>
        <header>
          <div>
            <strong>
              {`${!isEditMode ? ' Cadastrar ' : 'Editar'}`} Pedido
              {!isEditMode ? 's' : ''}
            </strong>
          </div>

          <div className='buttons-header' style={{ flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Link to='/pedidos'>
                <button id='btBack' type='button'>
                  <MdKeyboardArrowLeft size={20} color='#fff' />
                  Voltar
                </button>
              </Link>
              <button style={{ marginLeft: 10 }} id='btSave' type='submit'>
                <MdDone size={20} color='#fff' />
                Salvar
              </button>
            </div>
            <div>
              <input
                type='checkbox'
                {...(sendEmail ? { checked: true } : '')}
                onChange={handlesendEmail}
              />
              <label> Informar transportadora por e-mail:</label>
            </div>
          </div>
        </header>

        <div className='content'>
          <div className='row'>
            <div
              style={{ width: '140px' }}
              className={`input ${errors.id ? 'error' : ''}`}
            >
              <Input
                name='code'
                placeholder='Código'
                onChange={(e) => {
                  e.target.value = formatOrderCode(e.target.value);
                  handleChange(e);
                }}
                value={values.code}
                label='Código'
                disabled={id ? true : false}
                maxLength={11} // Total length including dots and hyphen (000.0000-AA)
              />

              {errors.id && <div className='validateError'>{errors.id}</div>}
            </div>

            <div className='qntTransported' style={{ width: '250px' }}>
              <Input
                name='qntTransported'
                placeholder='Quantidade'
                onChange={e => handleChangeQuantityTransported(e)}
                value={quantityTransported}
                type='number'
                label='Quantidade entregue:'
              />
            </div>
          </div>

          <div className='row'>
            <div className={styles['mult-select']}>
              <label>Cliente:</label>
              <Select
                className='select-options'
                value={customerValue}
                placeholder='Selecione um cliente'
                options={customerOptions}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'customer', setCustomerOptions)
                }
                onChange={option =>
                  handleChangeOptionCustomer(option as IOption)
                }
              />
            </div>

            <div className={styles['mult-select']}>
              <label>Transportadora:</label>
              <Select
                className='select-options'
                isMulti
                placeholder='Selecione uma transportadora'
                value={shippingValue}
                options={shippingOptions}
                styles={SelectCustomStyles}
                onInputChange={value =>
                  handleChangeInputSelect(
                    value,
                    'shipping-company',
                    setShippingOptions
                  )
                }
                onChange={options =>
                  handleChangeOptionShipping(options as IOption[])
                }
              />
            </div>
          </div>

          <div className='row'>
            <div>
              <label htmlFor='company'>Empresa:</label>
              <Select
                className='select-options'
                value={companySelected}
                placeholder='Selecione uma empresa'
                styles={{
                  control: base => ({
                    ...base,
                    width: '500px'
                  })
                }}
                options={companysOptions}
                onChange={option =>
                  handleChangeCompanyOption(option as IOption)
                }
              />
            </div>
          </div>

          <br />

          <div className='row flexpertinho'>
            <div className='select-product'>
              <label>Produtos:</label>
              <Select
                className='select-options'
                value={productOptions[0]}
                options={productOptions}
                onInputChange={value =>
                  handleChangeInputSelect(value, 'product', setProductOptions)
                }
                onChange={option =>
                  handleChangeOptionProduct(option as IOption)
                }
              />
            </div>

            <div className='qnt' style={{ width: '140px' }}>
              <Input
                name='quantity'
                placeholder='Quantidade'
                onChange={e => handleChangeQuantity(e)}
                value={quantity}
                type='number'
                label='Quantidade:'
              />
            </div>
            <button
              type='button'
              id='addProduct'
              onClick={handleAddProduct}
              {...(!quantity || !productValue.id
                ? { disabled: true }
                : { disabled: false })}
            >
              + Adicionar
            </button>
          </div>
        </div>

        <div className='products'>
          <fieldset>
            <legend>Produtos</legend>
            <table>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nome</th>
                  <th>Quantidade</th>
                  <th>Ação</th>
                </tr>
              </thead>
              <tbody>
                {orderItems.length > 0 ? (
                  orderItems.map((orderItem, index) => (
                    <tr key={index}>
                      <td>{orderItem.product.id}</td>
                      <td>{orderItem.product.name}</td>
                      <td>{orderItem.quantity}t</td>

                      <td>
                        <button type='button' className='actionButton blue'>
                          editar
                        </button>

                        <button
                          type='button'
                          className='actionButton red'
                          onClick={() => handleCorfirmDelete(orderItem)}
                        >
                          excluir
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      Nenhum resultado foi encontrado
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </fieldset>
        </div>
      </form>

      <br />
    </div>
  )
}

export default NewEditOrder
