import React, { useEffect, useState, ChangeEvent } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridColumnHeaderParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import './styles.css'
import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineSchedule
} from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'
import { PageLoading } from '../../components/PageLoading/PageLoading'

interface Customer {
  name: string
}

interface OrderItem {
  quantity: number
}

interface Scheduling {
  qtyTransported: number
}

interface Order {
  id: number
  code: number
  qtyTransported: number
  status: string
  createAt: Date
  customer: Customer
  orderItem: OrderItem[]
  scheduling: Scheduling[]
}

const Order: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const [orders, setOrders] = useState<Order[]>([])
  const [ordersFilter, setOrdersFilter] = useState<Order[]>([])
  const [modal, setModal] = useState('')
  const [name, setName] = useState('')
  const [id, setId] = useState(0)
  const [showStatus, setShowStatus] = useState(false)

  const { role, company } = useAuth()

  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    let path = ''

    if (role === 'Administrador') {
      path = '/order'
    } else if (role === 'Usuario') {
      path = `/order/company/${companyId}`
    } else {
      const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
      path = `/order/shipping-company/${shippingCompanyId}`
    }

    setLoading(true)
    api
      .get(path)
      .then(response => {
        setOrders(
          response.data.filter((order: Order) => parseInt(order.status) !== 100)
        )
        setOrdersFilter(response.data)
      })
      .finally(() => setLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // definição da tabela
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Code', width: 80, hide: true },
    { field: 'code', headerName: 'Code', width: 180 },

    { field: 'customer', headerName: 'Cliente', width: 400 },
    { field: 'date', headerName: 'Data', width: 110 },
    { field: 'qtyOreder', headerName: 'Quant. Pedida', width: 130 },
    { field: 'qtyDelivery', headerName: 'Quant. Entregue', width: 145 },
    { field: 'status', headerName: 'Status', width: 80 },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 100,

      renderHeader: (params: GridColumnHeaderParams) => {
        return (
          <>
            <span className='column__action'>
              Ação
              <span onClick={handleChangeShowStatus}>
                {!showStatus ? (
                  <AiOutlineEyeInvisible color='#666666' />
                ) : (
                  <AiOutlineEye color='#666666' />
                )}
              </span>
            </span>
          </>
        )
      },

      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.row.status !== '100%' && (
              <>
                <Tooltip
                  className='schedule-icon'
                  title={<h1>Carregamentos</h1>}
                >
                  <Link to={{ pathname: `/agendamento/novo/${params.row.id}` }}>
                    <AiOutlineSchedule />{' '}
                  </Link>
                </Tooltip>

                {role !== 'Transportadora' && (
                  <>
                    <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
                      <Link to={{ pathname: `/pedido/novo/${params.row.id}` }}>
                        {' '}
                        <AiOutlineEdit />{' '}
                      </Link>
                    </Tooltip>
                    {params.row.total! === 0 && (
                      <Tooltip className='delete-icon' title={<h1>Deletar</h1>}>
                        <Link to={{ pathname: `` }}>
                          <AiOutlineDelete
                            onClick={() => {
                              handleCorfirmDelete(
                                params.row.id,
                                params.row.name
                              )
                            }}
                          />
                        </Link>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }
    }
  ]
  // add linhas a tabela
  const rows = orders.map(item => {
    let qtyOrdered = ''
    let qtyDelivery = ''
    let schedulings = item.scheduling.filter(item => item.qtyTransported > 0)

    if (item.orderItem)
      qtyOrdered = Math.round(
        item.orderItem.reduce((accum, curr) => accum + curr.quantity, 0)
      ).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    if (item.scheduling)
      qtyDelivery = Math.round(
        item.scheduling.reduce(
          (accum, curr) => accum + curr.qtyTransported,
          0
        ) + item.qtyTransported
      ).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })

    return {
      id: item.id,
      code: item.code,
      date: new Date(item.createAt).toLocaleDateString(),
      status: parseFloat(item.status).toFixed(0) + '%',
      customer: item.customer.name,
      qtyOreder: qtyOrdered,
      qtyDelivery: qtyDelivery,
      total: schedulings.length
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value

    const results = ordersFilter.filter(
      order =>
        order.id.toString().includes(search.toLowerCase()) ||
        order.code.toString().includes(search.toLowerCase()) ||
        order.customer.name.toString().toLowerCase().includes(search)
    )
    setOrders(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: any, name: any) {
    showModal()
    setName(name)
    setId(parseInt(id))
  }

  function handleDeleteOrder(id: number) {
    hideModal()

    api
      .delete(`/order/delete/${id}`)
      .then(response => {
        if (response.status === 200) {
          toast.success('Excluido com sucesso!')
          const results = orders.filter(item => item.id !== id)

          setOrders(results)
        }
      })
      .catch(() => {
        toast.success('Erro ao excluir!')
      })
  }
  const showModal = () => {
    setModal('show')
  }
  const hideModal = () => {
    setModal('')
  }

  function handleChangeShowStatus() {
    setShowStatus(!showStatus)
    const results = ordersFilter.filter(
      order => parseInt(order.status) !== 100 || !showStatus
    )

    setOrders(results)
  }

  return (
    <div className='orderList'>
      <ModalDelete
        type='Pedido'
        name={name}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteOrder}
      />

      <header>
        <div>
          <strong>Gerenciar Pedidos</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input onChange={handleChangeSearch} placeholder='Buscar Pedido' />
            <MdSearch fontSize={26} color='#999999' />
          </div>
          {role !== 'Transportadora' && (
            <div className='new'>
              {' '}
              <Link to='/pedido/novo'>
                {' '}
                <button type='button'> Cadastrar </button>{' '}
              </Link>
            </div>
          )}
        </div>
      </header>

      {loading ? (
        <PageLoading loading />
      ) : (
        <div className='grid'>
          <div className='content'>
            <div style={{ height: 570 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={9}
                disableColumnMenu
                components={{
                  Toolbar: GridToolbar
                }}
                rowsPerPageOptions={[8]}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Order
