import { useEffect, useState } from 'react'
import { ShippingCompanyApi } from '../shippingCompanyTypes'
import { shippingCompanyService } from '../shippingCompanyService'

export function useShippingCompanyGetList() {
  const [loading, setLoading] = useState(false)
  const [shippingCompanies, setShippingCompanies] = useState<
    ShippingCompanyApi[]
  >([])

  async function fetchShippingCompanies() {
    try {
      setLoading(true)
      const response = await shippingCompanyService.getList()
      setShippingCompanies(response)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchShippingCompanies()
  }, [])

  return {
    loading,
    shippingCompanies,
    refetch: fetchShippingCompanies
  }
}
