import api from '../../services/api'
import { ShippingCompanyApi } from './shippingCompanyTypes'

async function getList() {
  const response = await api.get<ShippingCompanyApi[]>('/shipping-company')
  return response.data
}

export const shippingCompanyApi = {
  getList
}
