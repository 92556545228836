import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridCellParams,
  GridToolbar,
  ptBR
} from '@mui/x-data-grid'
import { Chip } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { MdSearch } from 'react-icons/md'
import api from '../../services/api'

import ModalDelete from '../../components/ModalDelete'
import { toast } from 'react-toastify'
import { useAuth } from '../../contexts/auth'
import { AiOutlineDelete, AiOutlineEdit, AiOutlineClose } from 'react-icons/ai'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import Select from 'react-select'
import './styles.css'
import { PageLoading } from '../../components/PageLoading/PageLoading'

interface Truck {
  id: number
  code: number
  driver: any
  licensePlate: string
  renavam: string
  owner: string
  vehicleModel: any
  implementType: string
  shaftQuantity: number
  grossCapacity: number
  netCapacity: number
  isEnabled: boolean
  shippingCompany: any
  company: any
}

interface IOption {
  label: string
  value: string
}
interface Company {
  id: number
  name: string
}

const Truck: React.FC = () => {
  const { role, company } = useAuth()

  const [loading, setLoading] = useState(false)

  const [trucks, setTrucks] = useState<Truck[]>([])
  const [trucksFilter, setTrucksFilter] = useState<Truck[]>([])
  const [companies, setCompanies] = useState<IOption[]>([])
  const [companyValue, setCompanyValue] = useState<IOption>({} as IOption)

  const [shippingOptions, setShippingOptions] = useState<IOption[]>([])
  const [shippingValue, setShippingValue] = useState<IOption>({} as IOption)

  const [modal, setModal] = useState('')
  const [truckLicensePlate, setTruckLicensePlate] = useState('')
  const [id, setId] = useState(0)

  const companyId = company || localStorage.getItem('@App:companyId')

  useEffect(() => {
    api.get(`/company`).then(response => {
      if (response.data) {
        setCompanies(
          response.data?.map((item: Company) => {
            return { label: `${item.id} - ${item.name}`, value: item.id }
          })
        )
      }
    })

    let path = ''

    if (role === 'Administrador') {
      path = '/truck'
    } else if (role === 'Usuario') {
      path = `/truck/company/${companyId}`
    } else {
      const shippingCompanyId = localStorage.getItem('@App:shippingCompanyId')
      path = `/truck/shipping-company/${shippingCompanyId}`
    }

    loadInputSelect()

    setLoading(true)
    api
      .get(path)
      .then(response => {
        const truckResponse: Truck[] = response.data

        const filteredTrucks = truckResponse.filter(truck =>
          truck.licensePlate.replace('-', '')
        )

        setTrucks(filteredTrucks)
        setTrucksFilter(filteredTrucks)
      })
      .finally(() => setLoading(false))

    function loadInputSelect() {
      handleChangeInputSelect('', 'company', setCompanies)
      handleChangeInputSelect('', 'shipping-company', setShippingOptions)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, role])

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Código', width: 80, hide: true },
    {
      field: 'owner',
      headerName: 'Proprietário',
      width: 240,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='owner'>
              <div className='code'>
                <div className='owner'>
                  <label>Código:</label>
                  <span>{params.row.id}</span>
                </div>
                <div className='owner'>
                  <span>{params.row.owner}</span>
                </div>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'vehicle',
      headerName: 'Veículo',
      width: 270,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='vehicle-info'>
              <div className='group'>
                <div className='licensePlate'>
                  <label>Placa:</label>
                  <span>{params.row.licensePlate}</span>
                </div>
                <div className='renavam'>
                  <label>Renavam:</label>
                  <span>{params.row.renavam}</span>
                </div>
              </div>
              <div className='vehicleModel'>
                <label>Modelo:</label>
                <span>{params.row.vehicleModel}</span>
              </div>
            </div>
          </>
        )
      }
    },
    {
      field: 'Capacity',
      headerName: 'Capacidades',
      width: 270,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <div style={{ lineHeight: 1 }} className='Capacity'>
              <div className='shaftQuantity'>
                <label>Qtd de eixo:</label>
                <span>{params.row.shaftQuantity}</span>
              </div>
              <div className='grossCapacity'>
                <label>Capac. bruta:</label>
                <span>{params.row.grossCapacity}</span>
              </div>
              <div className='netCapacity'>
                <label>Capac. líquida:</label>
                <span>{params.row.netCapacity}</span>
              </div>
            </div>
          </>
        )
      }
    },
    { field: 'implementType', headerName: 'Tipo de implemento', width: 200 },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <>
            {params.value ? (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'green',
                  fontSize: '15px'
                }}
                label='Ativo'
              />
            ) : (
              <Chip
                variant='outlined'
                style={{
                  height: '30px',
                  backgroundColor: '#FFF',
                  borderColor: 'red',
                  fontSize: '15px'
                }}
                label='Inativo'
              />
            )}
          </>
        )
      }
    },
    role !== 'Transportadora'
      ? { field: 'shippingCompany', headerName: 'Transportadora', width: 140 }
      : { field: 'shippingCompany', headerName: 'Transportadora', hide: true },
    role === 'Administrador'
      ? { field: 'company', headerName: 'Companhia', width: 140 }
      : { field: 'company', headerName: 'Companhia', hide: true },
    {
      field: 'action',
      headerName: 'Ação',
      sortable: false,
      width: 80,

      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Tooltip className='edit-icon' title={<h1>Editar</h1>}>
              <Link to={{ pathname: `/caminhao/novo/${params.row.id}` }}>
                {' '}
                <AiOutlineEdit />{' '}
              </Link>
            </Tooltip>
            <Tooltip
              className='delete-icon'
              title={
                <h1>{role === 'Administrador' ? 'Deletar' : 'Desvincular'}</h1>
              }
            >
              <Link to={{ pathname: '' }}>
                {role === 'Administrador' ? (
                  <AiOutlineDelete
                    onClick={() => {
                      handleCorfirmDelete(
                        params.row.id,
                        params.row.licensePlate
                      )
                    }}
                  />
                ) : (
                  <AiOutlineClose
                    onClick={() => {
                      handleCorfirmDelete(
                        params.row.id,
                        params.row.licensePlate
                      )
                    }}
                  />
                )}
              </Link>
            </Tooltip>
          </>
        )
      }
    }
  ]

  const rows = trucks.map(item => {
    return {
      id: item.id,
      licensePlate: item.licensePlate.replace(/\s/g, ''),
      renavam: item.renavam,
      owner: item?.driver?.name ? item.driver.name : item.owner,
      vehicleModel: item.vehicleModel?.name,
      implementType: item.implementType,
      shaftQuantity: item.shaftQuantity,
      grossCapacity: item.grossCapacity + 't',
      netCapacity: item.netCapacity + 't',
      status: item.isEnabled,
      shippingCompany: item.shippingCompany
        ? item.shippingCompany.name
        : 'Nenhuma',
      company: item.company ? item.company.company : 'Nenhuma'
    }
  })

  function handleChangeSearch(event: React.ChangeEvent<HTMLInputElement>) {
    const search = event.target.value.toLowerCase()

    const results = trucksFilter.filter(truck => {
      truck.vehicleModel = ''

      if (
        truck.id.toString().replace(/\s/g, '').toLowerCase().includes(search) ||
        truck.licensePlate
          .toString()
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(search) ||
        truck.renavam
          .toString()
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(search) ||
        truck.vehicleModel
          .toString()
          .replace(/\s/g, '')
          .toLowerCase()
          .includes(search)
      ) {
        return truck
      } else {
        return ''
      }
      //
    })

    setTrucks(results)
  }

  function handleCloseModal() {
    hideModal()
  }

  function handleCorfirmDelete(id: number, truckLicensePlate: any) {
    showModal()
    setTruckLicensePlate(truckLicensePlate)
    setId(id)
  }

  async function handleUnbidTruck(truckId: number) {
    const url = '/truck/unbind-shipping-company'

    try {
      await api.patch(url, {
        truck: {
          id: truckId
        }
      })

      toast.success('Caminhão desvinculado com sucesso')
      setTimeout(() => window.location.reload(), 1000)
    } catch (error: any) {
      toast.error('Erro ao Desvincular: ' + error.response.data.statusCode)
    }
  }

  async function handleDeleteTruck(truckId: number) {
    if (role === 'Administrador') {
      try {
        const response = await api.delete(`/truck/delete/${truckId}`)

        if (response.status === 200) {
          const results = trucks.filter(item => item.id !== truckId)

          setTrucks(results)
          toast.success('Excluido com sucesso!')
        }
      } catch (error: any) {
        if (error.response.data.message === 'ER_ROW_IS_REFERENCED_2') {
          toast.error(
            'Erro ao excluir, existe(m) pedido(s) para este caminhão!'
          )
        } else
          toast.error(
            'Erro ao excluir: ' + error.response.data.message || error.message
          )
      }
    } else {
      handleUnbidTruck(truckId)
    }

    hideModal()
  }

  const showModal = () => {
    setModal('show')
  }

  const hideModal = () => {
    setModal('')
  }

  function handleChangeInputSelect(
    value: string,
    patch: string,
    setOption: any
  ) {
    let options_: IOption[] = []

    if (value) {
      let path = `/${patch}/like/${value}`

      if (role !== 'Administrador') {
        path = path + `/company/${company}`
      }

      if (value === 'default') {
        path = `/${patch}`
      }

      if (companyValue.value !== '' && patch !== 'company') {
        path = path + `/company/${companyValue.value}`
      }

      api.get(path).then(response => {
        options_ = response.data.map((item: any) => {
          return {
            value: item.id,
            label: `${item.code ? item.code : item.id} - ${item.name}`
          }
        })
        setOption(options_)
      })
    }
  }

  function handleChangeOption(option: IOption, setValue: any, type: string) {
    setValue(option)
    const value = Number(option.value)

    if (type === 'company') {
      const results = trucksFilter.filter(item => item.company?.id === value)
      setTrucks(results)
      setShippingValue({ value: '', label: '' })
      setShippingOptions([])
    } else {
      const results = trucksFilter.filter(item => {
        if (companyValue.value) {
          return (
            item.company?.id === companyValue.value &&
            item.shippingCompany?.id === value
          )
        }

        return item.shippingCompany?.id === value
      })
      setTrucks(results)
    }
  }

  return (
    <div className='truckList'>
      <ModalDelete
        type='Caminhão'
        title={role === 'Transportadora' ? 'Desvincular' : 'Excluir'}
        name={truckLicensePlate}
        id={id}
        className={modal}
        handleClose={handleCloseModal}
        handleDeleteAction={handleDeleteTruck}
      />

      <header>
        <div>
          <strong>Gerenciar Caminhões</strong>
        </div>

        <div className='search-header'>
          <div className='search'>
            <input
              onChange={handleChangeSearch}
              placeholder='Buscar Caminhão'
            />
            <MdSearch fontSize={26} color='#999999' />
          </div>

          {role === 'Administrador' && (
            <div className='company'>
              <div className='select-company' style={{ width: 320 }}>
                <label>Empresa</label>
                <Select
                  className='select-options'
                  value={companyValue}
                  options={companies}
                  onInputChange={value =>
                    handleChangeInputSelect(value, 'company', setCompanies)
                  }
                  onChange={option =>
                    handleChangeOption(
                      option as IOption,
                      setCompanyValue,
                      'company'
                    )
                  }
                />
              </div>

              {companyValue && (
                <div className='truck-profile'>
                  <Button
                    className='erase-button t'
                    onClick={() => {
                      setCompanyValue({ value: '', label: '' })
                      setTrucks(trucksFilter)
                    }}
                  >
                    x
                  </Button>
                </div>
              )}
            </div>
          )}

          {role !== 'Transportadora' && (
            <>
              <div className='transportadora'>
                <div className='select-shipping' style={{ width: 500 }}>
                  <label>Transportadora</label>
                  <Select
                    className='select-options'
                    value={shippingValue}
                    options={shippingOptions}
                    onInputChange={value =>
                      handleChangeInputSelect(
                        value,
                        'shipping-company',
                        setShippingOptions
                      )
                    }
                    onChange={option =>
                      handleChangeOption(
                        option as IOption,
                        setShippingValue,
                        'shipping-company'
                      )
                    }
                  />
                </div>

                {shippingValue && (
                  <div className='truck-profile'>
                    <Button
                      className='erase-button t'
                      onClick={() => {
                        setShippingValue({ value: '', label: '' })
                        setTrucks(trucksFilter)
                      }}
                    >
                      x
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}

          <div className='new'>
            <Link to='/caminhao/novo'>
              <button type='button'>Cadastrar</button>
            </Link>
          </div>
        </div>
      </header>

      {loading ? (
        <PageLoading loading />
      ) : (
        <div className='grid'>
          <div className='content'>
            <div style={{ height: 556 }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={8}
                components={{
                  Toolbar: GridToolbar
                }}
                rowsPerPageOptions={[8]}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Truck
