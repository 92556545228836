import { useEffect, useState } from 'react'
import { CompanyApi } from '../companyTypes'
import { companyServices } from '../companyServices'

type Companys = CompanyApi[]

export function useCompanyGetList() {
  const [companys, setCompanys] = useState<Companys>([])

  async function getData() {
    try {
      const data = await companyServices.getList()
      setCompanys(data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return { companys }
}
