import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import Avatar from '@material-ui/core/Avatar'
import { Helmet } from 'react-helmet'

import './styles.css'

import logo from '../../../assets/images/logo-dark-1_a.svg'

import Sidebar from '../../Sidebar'

import { FiAlignJustify } from 'react-icons/fi'
import api from '../../../services/api'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { useAuth } from '../../../contexts/auth'

interface DefaultLayout {
  title: string
  ext?: boolean
}

const DefaultLayout: React.FC<DefaultLayout> = ({
  title,
  children,
  ext = false
}) => {
  const { setMenuExpand } = useAuth()
  const vw = window.innerWidth

  const storagedToken = sessionStorage.getItem('@App:token')
  const user = sessionStorage.getItem('@App:user')
  const companyName = localStorage.getItem('@App:companyName')

  api.defaults.headers.Authorization = `Bearer ${storagedToken}`

  const { menu } = useAuth()

  let history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  //const [active, setActive] = useState<any>()

  const { LogoutExped, driverLogout, role } = useAuth()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const handleClose = () => {
    setAnchorEl(null)
  }

  function logout() {
    if (window.location.pathname === '/expedicao') LogoutExped()
    else driverLogout()
  }

  function handleEdit() {
    if (role === 'Usuario' || role === 'Transportadora') {
      history.push('/user/edit')
    } else if (role === 'Administrador') {
      history.push('/admin/edit')
    }
    return true
  }

  const pageTitle = role === 'Transportadora'
    ? `Transportadora - ${title}`
    : role === 'Administrador'
      ? `Admin - ${title}`
      : title

  return (
    <section className='app '>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>

      <div className='_container'>
        <div
          className={`col1 ${menu ? ' sidebar-content active ' : 'sidebar-content'
            }  ${vw < 600 ? 'active' : ''}`}
        >
          <header>
            <span
              onClick={() => {
                setMenuExpand()
              }}
            >
              <FiAlignJustify fontSize='x-large' color='white' />
            </span>
          </header>
          <Sidebar />
        </div>

        <div className={`${menu ? ' col2 active ' : 'col2'}`}>
          <div className='topbar-content'>
            <div>
              <Link to='/dashboard'>
                {' '}
                <div id='logo'>
                  <img src={logo} alt='' />
                </div>
              </Link>
            </div>

            <div className='avatar'>
              <div>
                <div>
                  <IconButton
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleEdit}
                    color='inherit'
                  >
                    <Avatar>{user?.slice(0, 1).toUpperCase()}</Avatar>
                  </IconButton>

                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    open={false}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleEdit}>Perfil</MenuItem>
                    <MenuItem onClick={logout}>Sair</MenuItem>
                  </Menu>
                </div>

                {/* <Avatar>{user?.slice(0,1).toUpperCase()}</Avatar> */}
              </div>
              <div>
                <strong>{user}</strong>
                <strong>{companyName}</strong>
              </div>
            </div>
          </div>

          <div className={ext === true ? 'ext-grid' : ''}></div>
          <div id='content'>{children}</div>
        </div>
      </div>
    </section>
  )
}

export default DefaultLayout
